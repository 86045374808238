import React from "react"
import { Helmet } from "react-helmet-async"

import { Strip as StripModel } from "../api/schema"

import StripControls from "./StripControls"
import StripContainer from "./StripContainer"
import Banners from "../features/banners/Banners"
import CommentsSection from "../features/comments/CommentsSection"
import FeedGallery from "../features/feed/FeedGallery"
import { Footnote, Strip } from "."

type StripPageProps = {
  strip: StripModel
  jumpsBack?: boolean
  jumpsForward?: boolean
  onPrevious?: () => void
  onNext?: () => void
}

const StripPage: React.FC<StripPageProps> = ({
  jumpsBack = false,
  jumpsForward = false,
  onPrevious,
  onNext,
  strip,
}) => {
  const controls =
    onPrevious !== undefined || onNext !== undefined ? (
      <StripControls
        onPrevious={onPrevious}
        onNext={onNext}
        jumpsBack={jumpsBack}
        jumpsForward={jumpsForward}
      />
    ) : undefined

  const title =
    typeof strip.series == "object"
      ? `${strip.title} - ${strip.series.title}`
      : strip.title

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link
          rel="canonical"
          href={`https://fotostrips.nl/strip/${strip.id}`}
        />
      </Helmet>
      <section>
        <Strip strip={strip} controls={controls} />
      </section>
      <div className="additional-navigation">
        <StripContainer>{controls}</StripContainer>
      </div>
      <StripContainer>
        <Banners />
      </StripContainer>
      <section>
        {strip.enable_comments ? (
          <CommentsSection stripId={strip.id} />
        ) : (
          <StripContainer>
            <Footnote>Reacties zijn uitgeschakeld voor deze strip.</Footnote>
          </StripContainer>
        )}
      </section>
      <FeedGallery variant="footer" />
    </>
  )
}

export default StripPage
