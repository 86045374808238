import React, { useCallback, useState } from "react"

import { Keyword } from "./archiveSlice"

import classes from "./ArchiveNavigationBar.module.css"
import { Autocomplete, Button, Container } from "../../components"
import { PreviousIcon, SearchIcon } from "../../components/icons"
import { Pagination } from "../../components/Pagination"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import { DropdownLink } from "../../components/DropdownLink"

const SectionLinks = ({
  keys,
  onSelect,
}: {
  keys: number[] | undefined
  onSelect: (key: number | undefined) => void
}) => (
  <div className={classes.sections}>
    <Pagination
      limit={5}
      links={keys?.map((k) => ({ path: `/archief/${k}`, name: `${k}` })) ?? []}
      onSelect={(idx: number) => onSelect(keys![idx])}
    />
  </div>
)

const SearchField = ({
  defaultValue,
  keywords,
  onCancel,
  onSearch,
}: {
  defaultValue?: string
  keywords: Keyword[]
  onCancel?: () => void
  onSearch: (query: string) => void
}) => {
  return (
    <div className={classes.search}>
      <Autocomplete
        defaultValue={defaultValue}
        placeholder="Vul een zoekterm in + enter"
        name="search" // Name is unused but satisfies a Chrome recommendation for autofill.
        suggestions={keywords}
        autoFocus
        onSearch={onSearch}
      />
      {onCancel !== undefined ? (
        <button className={classes["cancel-button"]} onClick={onCancel}>
          <PreviousIcon />
        </button>
      ) : null}
    </div>
  )
}

const SearchButton = ({ onClick }: { onClick: () => void }) => (
  <Button
    onClick={onClick}
    variant="borderless"
    style={{ alignSelf: "center", fontSize: "1em" }}
  >
    <SearchIcon style={{ marginRight: 8 }} /> Zoeken
  </Button>
)

const ArchiveNavigationBar = ({
  sectionKeys,
  keywords,
  onSearch,
}: {
  sectionKeys: number[] | undefined
  keywords: Keyword[]
  onSearch: (query: string) => void
}) => {
  const { query } = useParams<{ query?: string }>()
  const [isSearching, setIsSearching] = useState(query !== undefined)
  const history = useHistory()
  const match = useRouteMatch<{ year: string }>({
    path: "/archief/:year",
    strict: true,
  })
  const year = match?.params.year !== "search" ? match?.params.year : undefined

  const handleToggleSearch = () => {
    setIsSearching((value) => !value)
  }

  const handleSelectSection = useCallback(
    (section: string | undefined) => {
      setIsSearching(false)
      history.push(`/archief/${section}`)
    },
    [history]
  )

  return (
    <div className={`${classes.navigationBar} sticky`}>
      <Container className={classes.content}>
        {isSearching ? (
          <SearchField
            defaultValue={query}
            keywords={keywords}
            onCancel={handleToggleSearch}
            onSearch={onSearch}
          />
        ) : (
          <SearchButton onClick={handleToggleSearch} />
        )}
        <div className={classes.separator} />

        {sectionKeys?.length ? (
          <DropdownLink
            name="y"
            value={query ? "" : year}
            values={sectionKeys.map(String)}
            onSelect={handleSelectSection}
            className={classes.yearSelector}
          />
        ) : null}
        <SectionLinks
          keys={sectionKeys}
          onSelect={(v) => handleSelectSection(String(v))}
        />
      </Container>
    </div>
  )
}

export default ArchiveNavigationBar
