import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { IoMdRefresh } from "react-icons/io"

import { fetchStatus } from "./cartSlice"
import { AppState, useAppDispatch } from "../../store"

import { Button, Container, Notice, Spinner } from "../../components"
import { Helmet } from "react-helmet-async"

const OrderConfirmation = () => {
  const dispatch = useAppDispatch()
  const { orderNumber } = useParams<{ orderNumber?: string }>()
  const { order, error, isLoadingOrder } = useSelector(
    (state: AppState) => state.cart
  )

  const num = orderNumber ?? order?.order_number
  useEffect(() => {
    if (num) {
      dispatch(fetchStatus(num))
    }
  }, [dispatch, num])

  if (isLoadingOrder) {
    return <Spinner centered />
  }

  if (error || !order || !num) {
    return (
      <Notice>
        {error ? `Er ging iets mis: ${error}` : "Geen bestelling gevonden."}
        <br />
        <br />
        <Button linkTo="/winkel">Terug naar de winkel</Button>
      </Notice>
    )
  }

  const handleRefresh = () => {
    dispatch(fetchStatus(num))
  }

  const Content = () => {
    switch (order.status) {
      case "created":
        return (
          <>
            <h1>Bestelstatus</h1>
            <p>
              Je bestelling #<strong>{order.order_number}</strong> is nog niet
              betaald.
            </p>
            <div>
              <Button variant="secondary" onClick={handleRefresh}>
                <IoMdRefresh /> Controleer opnieuw
              </Button>{" "}
              <Button external linkTo={order.redirect_url!}>
                Naar betaling
              </Button>
            </div>
          </>
        )
      case "paid":
      case "authorized":
        return (
          <>
            <h1>Bedankt!</h1>
            <p>
              Je bestelling (nummer <strong>{order.order_number}</strong>) is
              betaald en wordt verwerkt. We hebben je een e-mail gestuurd met de
              details.
            </p>
          </>
        )
      default:
        return null
    }
  }

  return (
    <Container>
      <Helmet>
        <title>Bestelstatus</title>
      </Helmet>
      <section>
        <Content />
      </section>
    </Container>
  )
}

export default OrderConfirmation
