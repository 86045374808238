import React, { useCallback } from "react"
import { composeClasses } from "../util/composeClasses"

interface DropdownLinkProps {
  className?: string
  name?: string
  value: string | undefined
  values: string[]
  onSelect: (value: string | undefined) => void
}
export function DropdownLink({
  className,
  name,
  value,
  values,
  onSelect,
}: DropdownLinkProps) {
  const handleSelection = useCallback<
    React.ChangeEventHandler<HTMLSelectElement>
  >(
    (event) => {
      onSelect(event.target.value)
    },
    [onSelect]
  )

  return (
    <select
      name={name}
      value={value}
      onChange={handleSelection}
      className={composeClasses(className, !value && "no-value")}
    >
      {[undefined, ...values].map((v) => (
        <option value={v ?? ""} key={v ?? ""} disabled={v === undefined}>
          {v ?? "Jaar"}
        </option>
      ))}
    </select>
  )
}
