import {
  QueryFields,
  aggregate,
  createItem,
  deleteItem,
  readItem,
  readItems,
  readSingleton,
} from "@directus/sdk"

import type Schema from "fs23-schema"
import type {
  ArchiveSection,
  BannersContent,
  Book,
  Comment,
  CommentLike,
  Order,
  OrderStatus,
  Strip,
} from "./schema"

import client from "./client"

const stripFields: QueryFields<Schema, Strip> = [
  "*",
  { book: ["title", "slug", { cover_image: ["*"] }] },
  { panels: [{ file: ["*"] }] },
  { series: ["title", "slug", "has_embedded_titles"] },
]

export async function getComments(strip: number) {
  return await client.request(
    readItems("comments", {
      fields: [
        "*",
        { owner: ["*", { avatar: ["*"] }, { membership: ["*"] }] },
        { likes: ["*", { owner: ["id", "display_name"] }] },
      ],
      filter: { strip: { _eq: strip } },
      sort: "created_on",
    })
  )
}

export async function postComment(comment: Partial<Comment>) {
  return await client.request(
    createItem("comments", comment, {
      fields: [
        "*",
        {
          owner: [
            "display_name",
            "hide_membership_badge",
            "verified",
            { avatar: ["*"] },
            { membership: ["*"] },
          ],
        },
        { likes: ["*"] },
      ],
    })
  )
}

export async function postLike({
  comment,
  owner,
}: {
  comment: number
  owner: { id: string; display_name: string }
}) {
  return await client.request(
    createItem(
      "comments_likes",
      { comment, owner: owner.id },
      { fields: ["*", { owner: ["id", "display_name"] }] }
    )
  )
}

export async function deleteLike(like: CommentLike) {
  if (like.id === undefined) {
    throw new Error("Undefined like")
  }
  await client.request(deleteItem("comments_likes", like.id))
}

export async function getStrip(id: number) {
  return await client.request(
    readItem("strips", id, {
      fields: stripFields,
    })
  )
}

export async function getFeed(offset: number, limit: number) {
  const strips = await client.request(
    readItems("strips", {
      fields: stripFields,
      filter: { feed: { _eq: true } },
      limit,
      offset,
      sort: ["-published_on"],
    })
  )
  return strips.slice().reverse()
}

export async function getArchive() {
  const response = await client.request(
    aggregate("strips", {
      aggregate: { count: "*" },
      groupBy: ["year(published_on)" as any],
      query: { filter: { series: { _eq: 1 } } },
    })
  )
  return response.map(
    (section): ArchiveSection => ({
      year: section.published_on_year,
      count: +(section.count ?? 0),
    })
  )
}

export async function getSection({
  year,
  limit,
  offset,
}: {
  year: number
  limit: number
  offset: number
}) {
  const countStrips = client
    .request(
      aggregate("strips", {
        aggregate: { count: "*" },
        query: {
          filter: {
            "year(published_on)": { _eq: year },
            series: { _eq: 1 },
          },
        },
      })
    )
    .then((response) => +(response[0].count ?? "0"))
  const getStrips = client.request(
    readItems("strips", {
      fields: stripFields,
      filter: {
        "year(published_on)": { _eq: year },
        series: { _eq: 1 },
      },
      limit,
      offset,
      sort: "published_on",
    })
  )
  const getBooks =
    offset === 0
      ? client.request(
          readItems("books", {
            fields: ["*", { cover_image: ["*"] }],
            filter: {
              "year(published_on)": { _eq: year },
              series: { _eq: 1 },
            },
            limit: -1,
            sort: "-published_on",
          })
        )
      : Promise.resolve([])
  const [filterCount, strips, books] = await Promise.all([
    countStrips,
    getStrips,
    getBooks,
  ])
  return { filterCount, strips, books }
}

export async function searchStrips({
  query,
  limit,
  offset,
}: {
  query: string
  limit: number
  offset: number
}) {
  const delimiter = ","
  const filter = {
    series: 1,
    _or: [
      { keywords: { _eq: query } }, // Caveat: a strip's singular keyword isn't get matched case-insensitively
      { keywords: { _icontains: `${delimiter}${query}${delimiter}` } },
      { keywords: { _istarts_with: `${query}${delimiter}` } },
      { keywords: { _iends_with: `${delimiter}${query}` } },
      { title: { _icontains: query } },
    ],
  } as any
  const [filterCount, strips] = await Promise.all([
    client
      .request(
        aggregate("strips", {
          aggregate: { count: "*" },
          query: { filter },
        })
      )
      .then((response) => +(response[0].count ?? "0")),

    client.request(
      readItems("strips", {
        fields: stripFields,
        filter,
        limit,
        offset,
        sort: "published_on",
      })
    ),
  ])
  return { filterCount, strips }
}

export async function getInfo() {
  return await client.request(
    readSingleton("info", { fields: ["*", { image: ["*"] }] })
  )
}

export async function getKeywords() {
  type Response = { data: { value: string; count: number }[] }
  const url = new URL("/keywords", client.url)
  url.searchParams.append("series", "1")
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  const result = (await response.json()) as Response
  return result.data
}

export async function getSeriesList() {
  return await client.request(
    readItems("series", {
      fields: ["*", { cover_image: ["*"] }],
      filter: { status: { _eq: "published" } },
      sort: "sort",
    })
  )
}

export async function getSeries(slug: string) {
  const result = await client.request(
    readItems("series", {
      fields: [
        "*",
        { cover_image: ["*"] },
        {
          strips: [
            "*",
            { panels: [{ file: ["*"] }] },
            { book: ["slug", "title", { cover_image: ["*"] }] },
          ],
        },
        {
          books: [
            "id",
            "slug",
            "title",
            "price",
            "shop_state",
            { cover_image: ["*"] },
          ],
        },
      ],
      filter: { slug: { _eq: slug } },
    })
  )
  return result[0]
}

export async function getMainSeries() {
  return await client.request(
    readItem("series", 1, {
      fields: [
        "id",
        "description",
        "has_embedded_titles",
        "sort",
        "slug",
        "status",
        "title",
        "payoff",
        { cover_image: ["*"] },
      ],
    })
  )
}

export async function getBanners() {
  const banners = await client.request(
    readSingleton("banners", {
      fields: [
        {
          content: [
            "*",
            {
              item: {
                books: [
                  "slug",
                  "title",
                  { cover_image: ["*"] },
                  { series: ["slug", "title"] },
                ],
                generic_banners: ["*"],
              },
            },
          ],
        },
      ],
    })
  )
  if (banners.content === undefined) {
    return []
  }
  return banners.content as BannersContent[]
}

export async function getBooks() {
  const result = await client.request(
    readItems("books", {
      fields: ["*", { cover_image: ["*"] }, { series: ["*"] }],
      sort: "-published_on",
    })
  )
  return result.map((book) => ({
    ...book,
    price: book.price !== null ? +book.price : null,
  }))
}

export async function getBook(slug: string): Promise<Book> {
  const result = await client.request(
    readItems("books", {
      fields: [
        "*",
        { strips: ["*", { panels: [{ file: ["*"] }] }, { series: ["*"] }] },
        { cover_image: ["*"] },
        { series: ["*"] },
      ],
      filter: { slug: { _eq: slug } },
    })
  )
  const book = result[0]
  return {
    ...book,
    price: book.price !== null ? +book.price : null,
  }
}

export async function getShipping() {
  const info = await client.request(
    readSingleton("info", { fields: ["shipping_costs"] })
  )
  return info.shipping_costs
}

export async function postOrder(order: Partial<Order>) {
  const payload: Partial<Order> = {
    ...order,
    redirect_url: process.env.PUBLIC_URL,
  }
  const url = new URL(`/shop/order`, client.url)
  let headers = {
    "Content-Type": "application/json",
    Authorization: "",
  }
  const token = await client.getToken()
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers,
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  const result = (await response.json()) as OrderStatus
  return result
}

export async function getOrderStatus(orderNumber: string) {
  const url = new URL(`/shop/status/${orderNumber}`, client.url)
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return (await response.json()) as OrderStatus
}

export async function getPage(slug: string) {
  return await client.request(readItem("pages", slug))
}

export interface PaymentMethod {
  id: string
  description: string
  image: {
    svg: string
  }
}

export async function getPaymentMethods({
  amount,
  locale,
}: {
  amount?: number
  locale?: string
}) {
  const url = new URL(`/shop/methods`, client.url)
  if (amount) {
    url.searchParams.append("amount", amount.toFixed(2))
  }
  if (locale) {
    url.searchParams.append("locale", locale)
  }
  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return (await response.json()) as PaymentMethod[]
}
